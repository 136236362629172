import {Theme} from '@mui/material'

const Button = (theme: Theme) => {
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    '@media (min-width:0px)': {minWidth: '30px', padding: '6px 8px'},
                    '@media (min-width:600px)': {minWidth: '98px', padding: '6px 16px'},
                    '@media (min-width:900px)': {minWidth: '98px'},
                },
            },
        },
    }
}

export default Button
