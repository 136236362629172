import {Box, Button, Grid, Typography} from "@mui/material";
import {theme} from "../stylesheet";
import {useEffect, useRef, useState} from "react";
import {useCountdown} from "../functions/useCountdown";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
interface props {
    title: string;
    description:string;
    time:string;
    nextH:()=>void;
    backH:()=>void;
}

function Eventgen(prop: props) {
    const targetDate = prop.time; // Set your target date and time here
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'left',
            }
            }>
                <Box>
                    <Grid container spacing={4}>
                        <Grid item md={3}>
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <Box sx={{
                                    background: theme.palette.secondary.main,
                                    padding: '10px',
                                    color: theme.palette.customColors.brandColors.tertiary,
                                    width: '100%',
                                    textAlign: 'center',
                                    fontSize: "30px",
                                    fontWeight: '600'
                                }}>{days}</Box>
                                <Typography width={"100%"} align={'center'}>Days</Typography>
                            </Box>
                        </Grid>
                        <Grid item md={3}>
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <Box sx={{
                                    background: theme.palette.secondary.main,
                                    padding: '10px',
                                    color: theme.palette.customColors.brandColors.tertiary,
                                    width: '100%',
                                    textAlign: 'center',
                                    fontSize: "30px",
                                    fontWeight: '600'
                                }}>{hours}</Box>
                                <Typography width={"100%"} align={'center'}>hours</Typography>
                            </Box>
                        </Grid>
                        <Grid item md={3}>
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <Box sx={{
                                    background: theme.palette.secondary.main,
                                    padding: '10px',
                                    color: theme.palette.customColors.brandColors.tertiary,
                                    width: '100%',
                                    textAlign: 'center',
                                    fontSize: "30px",
                                    fontWeight: '600'
                                }}>{minutes}</Box>
                                <Typography width={"100%"} align={'center'}>minutes</Typography>
                            </Box>
                        </Grid>
                        <Grid item md={3}>
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <Box sx={{
                                    background: theme.palette.secondary.main,
                                    padding: '10px',
                                    color: theme.palette.customColors.brandColors.tertiary,
                                    width: '100%',
                                    textAlign: 'center',
                                    fontSize: "30px",
                                    fontWeight: '600'
                                }}>{seconds}</Box>
                                <Typography width={"100%"} align={'center'}>seconds</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    padding: '20px'
                }}>
                    <Typography variant={"h6"} sx={{
                        marginRight: '30px',
                        fontWeight:'600',
                        display:{xs:'none',md:'block'}
                    }} fontWeight={'300'}>Remaining
                    </Typography>
                </Box>


            </Box>
            <Box sx={{

                margin:{
                    xs:"20px 20px 20px 20px",
                    md:"50px 50px 0px 10px"

                }
            }}>
                <Typography variant={'h5'} fontWeight={'600'}> {prop.title}</Typography>
                <Typography sx={{
                    marginTop:'30px'
                }}>{prop.description}
                </Typography>
                <Button color={'primary'} sx={{
                    background: theme.palette.primary.main,
                    color: theme.palette.customColors.brandColors.tertiary,
                    marginTop: '20px',
                    padding:'16px 23px !important'
                }}>Join with Us</Button>

            </Box>
            <Box sx={{
                width:'100%',
                display:'flex',
                justifyContent:'right'
            }}>
                <Box onClick={prop.backH} sx={{
                    background:theme.palette.secondary.main,
                    color:'white',
                    width:'20px',
                    height:'20px',
                    padding:'10px 10px 10px 15px',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    margin:'2px'

                }}><ArrowBackIosIcon/></Box>
                <Box onClick={prop.nextH} sx={{
                    background:theme.palette.secondary.main,
                    color:'white',
                    width:'20px',
                    height:'20px',
                    padding:'10px 10px 10px 15px',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    margin:'2px'

                }}>
                    <ArrowForwardIosIcon/> </Box>

            </Box>
        </Box>
    );
}

export default Eventgen