import {CustomColors} from './customColors'

const DefaultPalette = () => {
    const brandColors = {
        primary: '#E77817',
        secondary: '#14418B',
        tertiary: '#FFFFFF',

    }

    const customColors: CustomColors = {
        brandColors,
        blackValues: {
            100: '#F7F7F7',
            200: '#D1D6DC',
            300: '#9B9B9B',
            400: '#555555',
            500: '#222222',
        },
        colors: {
            primary: brandColors.primary,
            secondary: brandColors.secondary,
            tertiary: brandColors.tertiary,
            error: '#FF0707',
            gridHeader: '#CBE8F8',
            black: '#222222',
            textContent: '#7A4DE5',
            inputText: '#17D521',
            background: '#181D23',
            success: '#4CAF50',
        },
    }

    return {
        customColors: customColors,
        background: {
            default: "#FFFFFF",
          },
        primary: {
            main: customColors.brandColors.primary,
        },
        secondary: {
            main: customColors.brandColors.secondary,
        },
        tertiary: {
            main: customColors.brandColors.tertiary,
        },
        success: {
            main: customColors.colors.success,
        },
        error: {
            main: customColors.colors.error,
        },
        warning: {
            main: customColors.brandColors.tertiary,
        },
        info: {
            main: customColors.brandColors.primary,
        },
    }
}

export default DefaultPalette
