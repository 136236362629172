import {Box, Grid, Typography} from "@mui/material";
import {theme} from "../stylesheet";
import eventimg from "../assets/images/eventimage.png";
import Eventgen from "./Eventgen";
import {useState} from "react";
import event1img from '../assets/images/events/Web 2.jpg';
import event2img from '../assets/images/events/Vc Sir Size 02.jpg';
import event3img from '../assets/images/events/se.jpg';

function EventBar() {
    const [index,setIndex] = useState(0);
    const events = [
        {
            title:'UWU Legends League',
            description:'UWU Legends League Season 2 returns to Buddadasa Ground on December 17th! This cherished cricketing tradition for UWU alumni started in 2022, uniting graduates for a day of cricket and camaraderie. Last time, UWU Generation 11 Wolf Worriers secured the championship, with EX09 UWU 4th generations as runners-up.\n' +
                '\n' +
                'Mark your calendars for another thrilling edition as we welcome the 13th generation to add new energy to this inclusive event. Proudly organized by the UWU Alumni Association, the Legends League stands as a testament to alumni dedication in preserving the UWU spirit.\n' +
                '\n' +
                'Save the date, rally your batchmates, and get ready for a day filled with cricket brilliance, laughter, and the unwavering spirit of UWU. Let the countdown to UWU Legends League Season 2 begin, creating another chapter in UWU\'s vibrant alumni community history!\n',
            image:event1img,
            time:'2023-12-17T00:00:00Z'
        },
        {
            title:'Felicitation for Vice Chancellor',
            description:"Professor Jayantha Lal Rathnasekara the Vice Chancellor of Uva Wellassa University has been in office for the last six years at Uva Wellassa University. During his tenure UWU had been elevated to a different height in its short journey of past 17 years. \n" +
                "\n" +
                "In paving the gratitide for his untiring efforts for the last 6 years the Alumni Association of Uva Wellassa University has come forward to host a special felicitation for Prof. J.L. Rathnasekara at the Inaugural Annual General Meeting of the Alumni Association of Uva Wellassa University on the 15th of October 2023.\n",
            image:event2img,
            time:'2023-12-10T00:00:00Z'
        },
        {
            title:'Mr. Charith Wimaladharma Scholarship Program for UWU Undergraduates',
            description:'\n' +
                'Alumni Association of Uva Wellassa University is embarking on a scholarship scheme “CHARITHA WIMALADHARMA MEMORIAL SCHOLARSHIP” to UWU Undergraduates. The "Charitha Wimaladarma Scholarship Fund"  will facilitates UWU Undergraduates to overcome their financial barriers and to reach their Academic and Research Excellence\n'
            ,
            image:event3img,
            time:'2023-12-10T00:00:00Z'
        }
    ];
const backbtnH = ()=>{
    console.log("backbtn click");
    if(index>0){
        setIndex(index-1);
    }else{
        setIndex(events.length-1);
    }
}

    const nexbtnH = ()=>{
        console.log("index",index);
        if(index<events.length-1){
            setIndex(index+1);
        }else{
            setIndex(0);
        }
    }

//console.log(events.length);

    return (
        <Box id={"Event"} sx={{
            width: "90%",
            marginTop: '50px',
            marginBottom:'50px',
            background:'#EFF1F4',
            paddingTop:'30px',
            paddingRight:'30px',
            pb:'30px',
            position:'relative',
        }}>
            <Typography align={'right'} variant={'h3'} sx={{
                width: '100%',
                color: '#091D3D',
                fontWeight: '600',
                marginBottom: '50px',
            }}>
                Upcoming Event
            </Typography>

            <Box sx={{
                width: '100%',
                marginTop:'50px',
                height:'100%'

            }}>
                <Grid container spacing={5}>
                    <Grid item md={12} lg={6} sm={12}>

                        <img src={events[index].image} style={{
                            width: '100%',

                        }}/>
                    </Grid>

                    <Grid item md={12} lg={6} sm={12}>

                        <Eventgen nextH={nexbtnH} backH={backbtnH} time={events[index].time} title={events[index].title} description={events[index].description}/>
                    </Grid>
                </Grid>

            </Box>


        </Box>


    );
}

export default EventBar