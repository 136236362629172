import {
    AppBar,
    Box,
    Button,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Toolbar,
    Typography,
} from "@mui/material";
import * as React from "react";
import logo from "../assets/images/applogo.png";
import { theme } from "../stylesheet";
interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

const drawerWidth = 240;
const navItems = ["Home", "About","Event","Gallery","Blogs", "Contact"];

function AppBarh(props: Props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
            <div>
                 <img src={logo} style={{width:'100px'}}/>

            </div>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <a id={`#${item}`}>
                        <ListItemButton sx={{ textAlign: "center" }}>
                            <ListItemText primary={item} />
                        </ListItemButton>
                        </a>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container =
        window !== undefined ? () => window().document.body : undefined;
    return (

        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderTop:'10px solid',
                borderColor:theme.palette.secondary.main
            }}
        >
            {/* <CssBaseline /> */}
            <AppBar
                position="static"
                sx={{
                    background: theme.palette.background.default,
                    boxShadow: "none",
                    color: "black",
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: "none" } }}
                    >
                        <svg
                            viewBox="0 0 1024 1024"
                            fill="currentColor"
                            height="1em"
                            width="1em"
                        >
                            <path d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z" />
                        </svg>
                    </IconButton>
                    <div>
                         <img src={logo} style={{width:'200px',marginLeft:'20px'}}/>

                    </div>

                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
                    ></Typography>
                    <Box sx={{ display: { xs: "none", sm: "block" } }}>

                        {navItems.map((item) => (
                            <a href={`#${item}`}>
                            <Button
                                key={item}
                                sx={{ color: "black" }}
                            >
                                {item}
                            </Button></a>
                        ))}
                    </Box>

                    {/*<div>*/}
                    {/*    <Button*/}
                    {/*        sx={{*/}
                    {/*            padding: "10px 20px",*/}
                    {/*            background: theme.palette.primary.main,*/}
                    {/*            borderRadius: "13px",*/}
                    {/*            color: "white",*/}
                    {/*            marginLeft: "50px",*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        Sign Up*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </Box>
    );
}
export default AppBarh;
