import {Box, Button, Grid, Typography} from "@mui/material";
import logo from '../assets/images/whitelogo.png';
import {theme} from "../stylesheet";
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import qlogo from '../assets/icons/QrioMatrixLogo.png';

function Footer(){
    return(
        <Box id={'Contact'}>
        <Box sx={{
            width:'100%',
            display:'flex',
            background:'#343740',
            color:theme.palette.customColors.brandColors.tertiary,
            justifyContent:'center',
            paddingTop:'40px',
            paddingBottom:'40px'
        }}>
            <Box sx={{
                width:'85%',

            }}>
                <Grid container spacing={5}>
                    <Grid item md={4}>
                            <img src={logo} width={'100%'}/>
                        <Typography sx={{
                            marginTop:'20px'
                        }}>We support and provide ways for our alumni and friends to get involved in the life of the Uva Wellassa University. We also work to ensure that every member of our community can access our world-class services and facilities.
                            Our programme of events brings our alumni together to celebrate, debate and collaborate, while our national network allows alumni to reconnect and find new arenas to contribute to support our future generation

                        </Typography>
                    </Grid>

                    <Grid item md={2} sx={{
                        display:'flex',
                        flexDirection:'column'
                    }}>

                        <Typography sx={{
                            padding:'30px 30px 0px 30px'
                        }}>
                            About Us
                        </Typography>
                        <Typography sx={{
                            padding:'30px 30px 0px 30px'
                        }}>
                            Team
                    </Typography>
                        <Typography sx={{
                            padding:'30px 30px 0px 30px'
                        }}>
                            Page
                        </Typography>
                        <Typography sx={{
                            padding:'30px 30px 0px 30px'
                        }}>
                            Services
                        </Typography>
                        <Typography sx={{
                            padding:'30px 30px 0px 30px'
                        }}>
                            News
                        </Typography>
                    </Grid>
                    <Grid item md={3} >
                        <Box sx={{
                            display:'flex',
                            flexDirection:'column',
                            alignItems:'left'
                        }}>
                        <Box sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'left',
                            marginTop: '20px'
                        }}>
                            <CallIcon/>
                            <Typography sx={{
                                marginLeft: '10px'
                            }}>
                                +94 552 226 622



                            </Typography></Box>

                        <Box sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'left',
                            marginTop: '20px'
                        }}>
                            <EmailIcon/>
                            <Typography sx={{
                                marginLeft: '10px'
                            }}>
                                alumni@std.uwu.ac.lk


                            </Typography></Box>

                        <Box sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'left',
                            marginTop: '20px'
                        }}>
                            <FmdGoodIcon/>
                            <Typography sx={{
                                marginLeft: '10px'
                            }}>
                                Uva Wellassa University, Passara Road, Badulla.


                            </Typography></Box>
                        </Box>
                    </Grid>

                    <Grid item md={3} sx={{
                        marginTop: '20px'
                    }}>
                        <Typography fontWeight={600}>
                            Follow Us
                        </Typography>

                        <Box sx={{
                            display:'flex',
                            width:'90%',
                            marginTop:'20px',
                            justifyContent:'space-between'
                        }}>
                            <a href={'https://web.facebook.com/uwualumni'} target={'_blank'}> <Box sx={{
                                color:theme.palette.customColors.brandColors.tertiary,
                                background:theme.palette.primary.main,
                                borderRadius:'50%',
                                padding:'10px',
                                width:'10px',
                                height:'10px',
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                                margin:'10px'
                            }}>
                                <FacebookIcon fontWeight={800}/>
                            </Box></a>
                            <a href={'https://web.facebook.com/uwualumni'} target={'_blank'}>
                            <Box sx={{
                                color:theme.palette.customColors.brandColors.tertiary,
                                background:theme.palette.primary.main,
                                borderRadius:'50%',
                                padding:'10px',
                                width:'10px',
                                height:'10px',
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                                margin:'10px'
                            }}>
                                <TwitterIcon fontWeight={800}/>
                            </Box>
                            </a><a href={'https://web.facebook.com/uwualumni'} target={'_blank'}>

                            <Box sx={{
                                color:theme.palette.customColors.brandColors.tertiary,
                                background:theme.palette.primary.main,
                                borderRadius:'50%',
                                padding:'10px',
                                width:'10px',
                                height:'10px',
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                                margin:'10px'
                            }}>
                                <LinkedInIcon fontWeight={800}/>
                            </Box></a><a href={'https://web.facebook.com/uwualumni'} target={'_blank'}>

                            <Box sx={{
                                color:theme.palette.customColors.brandColors.tertiary,
                                background:theme.palette.primary.main,
                                borderRadius:'50%',
                                padding:'10px',
                                width:'10px',
                                height:'10px',
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                                margin:'10px'
                            }}>
                                <InstagramIcon fontWeight={800}/>
                            </Box></a><a href={'https://web.facebook.com/uwualumni'} target={'_blank'}>
                            <Box sx={{
                                color:theme.palette.customColors.brandColors.tertiary,
                                background:theme.palette.primary.main,
                                borderRadius:'50%',
                                padding:'10px',
                                width:'10px',
                                height:'10px',
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                                margin:'10px'
                            }}>
                                <YouTubeIcon fontWeight={800}/>

                            </Box></a>


                        </Box>
                        <Typography fontWeight={600} sx={{
                            marginTop:'20px'
                        }}>
                            Sign up for Latest News
                        </Typography>

                        <a href={'https://www.threads.net/@uwualumniassociation'} target={'_blank'}>    <Button color={'primary'} sx={{
                                background:theme.palette.primary.main,
                            padding:'10px 20px !important',
                            color:theme.palette.customColors.brandColors.tertiary,
                            marginTop:'20px'
                        }}>Subscribe</Button></a>
                    </Grid>

                </Grid>

            </Box>

        </Box>
    <Box sx={{
        width:'100%',
        color:theme.palette.customColors.brandColors.tertiary,
        //background: 'rgb(35,37,43)',
        background:' linear-gradient(128deg, rgba(35,37,43,1) 0%, rgba(54,57,65,1) 48%, rgba(86,97,131,1) 100%)',
        display:'flex',
        flexDirection:{xs:'column',sm:'column',md:'row',lg:'row'},
        justifyContent:{xs:'center',sm:'center',md:'space-around',xl:'space-around'},
        alignItems:'center'
    }}>
        <Box><Typography align={'center'} fontWeight={100} sx={{
            padding:'30px',
            color:'rgba(225,225,225,0.7)',

        }}>
            Copyright © 2023 Alumni Association | Uva Wellassa University. All Rights Reserved.
        </Typography></Box>
        <Box sx={{
            display:'flex',
            width:'300px'

            // width:{sm:'100px',md:'100px',lg:'200px',xl:'200px'}
        }}>
           <Typography align={'center'} fontWeight={100} sx={{
                padding:'30px 3px 30px 3px',
                color:'rgba(225,225,225,0.7)'
            }}>
                Developed By
            </Typography>
            <a href={'https://qriomatrix.com/'}> <img src={qlogo}  width={'150px'} style={{
                margin:'20px'
            }}/>
           </a>
        </Box>
        </Box>

    </Box>

    );
}
export default Footer